import { useMutation } from '@tanstack/react-query';
import { SITE_LAYOUT_URL } from 'hooks/site/useSiteLayout';
import { useNavigate } from 'react-router-dom';
import httpClient from 'services/httpClient';
import queryClient from 'services/queryClient';
import { ConnectParams, ConnectResponse } from 'types/ConnectTypes';

export const useConnect = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (params: ConnectParams) => {
      const { data } = await httpClient.post<ConnectResponse>(`/auth/connect`, {
        ...params,
      });

      return data;
    },
    onSuccess: data => {
      localStorage.setItem('token', data.token);
      queryClient
        .invalidateQueries({ queryKey: [SITE_LAYOUT_URL] })
        .then(() => navigate('/'));
    },
  });
};
