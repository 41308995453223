import {
  Accordion,
  Avatar,
  Button,
  FileInput,
  Label,
  Textarea,
} from 'flowbite-react';
import { Tab } from 'types/element-types/TabType';

interface TabReplyRequiredBodyProps {
  tab: Tab;
}

export const TabReplyRequiredBody: React.FC<TabReplyRequiredBodyProps> = ({
  tab,
}) => {
  return (
    <>
      {tab.messageList && (
        <Accordion>
          {tab.messageList.items.map(item => (
            <Accordion.Panel>
              <Accordion.Title className="w-full">
                <div className="flex items-center justify-between w-full">
                  <Avatar placeholder={item.sender.initials} rounded>
                    {`${item.sender.fname} ${item.sender.lname}`}
                    <p className="text-xs text-gray-400">
                      {item.timeStamp.value}
                    </p>
                  </Avatar>
                </div>
              </Accordion.Title>
              <Accordion.Content>
                <Textarea
                  readOnly
                  value={item.body.value || ''}
                  className="resize-none focus:border-gray-300 focus:ring-0"
                />
                {item.attachments && (
                  <div>
                    {item.attachments.items.map((item, index) => (
                      <p
                        className="text-xs text-blue-600 underline cursor-pointer"
                        key={index}
                      >
                        {item.value}
                      </p>
                    ))}
                  </div>
                )}
              </Accordion.Content>
            </Accordion.Panel>
          ))}
        </Accordion>
      )}
      <hr className="my-5" />
      {tab.sendSection && (
        <>
          <div className="space-y-5">
            <Textarea
              className="resize-none"
              placeholder={tab.sendSection.body.blur}
              disabled={tab.sendSection.body.disabled}
              rows={3}
            />
            <div>
              {tab.sendSection.attachments.label.show && (
                <Label value={tab.sendSection.attachments.label.text} />
              )}
              <FileInput
                multiple={tab.sendSection.attachments.type === 'multi'}
                disabled={tab.sendSection.attachments.disabled}
                helperText={tab.sendSection.attachments.label.description}
              />
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <Button
              disabled={tab.sendSection.send.disabled}
              className="w-[200px]"
              color="blue"
            >
              {tab.sendSection.send.text}
            </Button>
          </div>
        </>
      )}
    </>
  );
};
