import { ViewRecordModalContext } from 'components/Table/TableComponent';
import { useContext } from 'react';

export const useViewRecordModalContext = () => {
  const context = useContext(ViewRecordModalContext);

  if (context === undefined)
    throw new Error(
      'useViewRecordModalContext must be inside a useViewRecordModalContextProvider',
    );

  return context;
};
