import { SiteLayoutMenu } from 'types/SiteLayout';

import { SidebarMenuItem } from './SidebarMenuItem';

interface SidebarMenuProps {
  isToggled: boolean;
  menu: SiteLayoutMenu;
}

export const SidebarMenu = ({ isToggled, menu }: SidebarMenuProps) => {
  return (
    <div className="flex-grow w-full mt-4">
      <ul className="flex flex-col ">
        <li className="w-full mx-0 list-none leading-10 space-y-2">
          {menu.items.map((item, index) => (
            <div key={index}>
              <SidebarMenuItem isToggled={isToggled} {...item} />
            </div>
          ))}
        </li>
      </ul>
    </div>
  );
};
