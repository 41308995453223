import { useContext } from 'react';
import { ModalContext } from 'templates/Datatabled_v1';

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (context === undefined)
    throw new Error('useModalContext must be inside a ParamsProvider');

  return context;
};
