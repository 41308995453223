import { Header } from 'components/Header';
import { Sidebar } from 'components/Sidebar';
import { Flowbite } from 'flowbite-react';
import useSiteLayout from 'hooks/site/useSiteLayout';
import { Outlet } from 'react-router-dom';
import { customTheme } from 'theme-utils/customThemes';

export const Default = () => {
  const { data: siteLayoutData } = useSiteLayout();
  return (
    <>
      <Flowbite theme={{ theme: customTheme }}>
        <Header />
        <main className="flex flex-grow overflow-hidden">
          {/* Show sidebar only to authenticated users */}
          {siteLayoutData?.layout.type == 'auth' && <Sidebar />}{' '}
          <div className="overflow-x-auto grow w-full">
            <Outlet />
          </div>
        </main>
      </Flowbite>
    </>
  );
};
