import logo from 'assets/images/AMX360Logo.svg';
import { Page } from 'types/PageTypes';

interface ErrorProps {
  page: Page;
}

export const Error: React.FC<ErrorProps> = ({ page }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-8 mt-14 text-gray-900">
      <div className="text-center md:space-y-2">
        <p className="text-4xl md:text-7xl font-black ">
          {page.content?.title}
        </p>
        <p className="text-base md:text-xl tracking-normal md:tracking-widest font-semibold">
          {page.content?.description}
        </p>
      </div>
      <p className="text-md md:text-2xl font-semibold">
        {page.content?.advisory}
      </p>
      <img src={logo} alt="Logo" className="h-8 md:h-10 mt-2" />
    </div>
  );
};
