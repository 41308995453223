import { useQuery } from '@tanstack/react-query';
import httpClient from 'services/httpClient';
import { ViewRecordResponse } from 'types/ViewRecordTypes';

export const VIEW_URL = 'support/tickets';

const useViewTicket = (id: string) => {
  return useQuery({
    queryKey: [VIEW_URL + '/view', id],
    queryFn: async () => {
      const { data } = await httpClient.get<ViewRecordResponse>(
        `${VIEW_URL}/${id}/view`,
      );

      return data;
    },
    enabled: !!id,
  });
};

export default useViewTicket;
