import { useMutation } from '@tanstack/react-query';
import httpClient from 'services/httpClient';

const SEND_MESSAGE = 'support/tickets';

interface Attachment {
  name: string;
  size: number;
}

interface SendMessageBody {
  body: string;
  attachments: Attachment[];
}

interface SendMessageParams {
  supportTicketId: string;
  requestBody: SendMessageBody;
}

const useSendMessage = () => {
  return useMutation({
    mutationFn: async ({ supportTicketId, requestBody }: SendMessageParams) => {
      await httpClient.post<void>(
        `${SEND_MESSAGE}/${supportTicketId}/messages/action-send`,
        requestBody,
      );
    },
  });
};

export default useSendMessage;
