import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'flowbite-react';
import { useModalContext } from 'hooks/useModalContext';
import { Toolbar } from 'types/PageTypes';

interface ToolBarComponentProps {
  toolBar: Toolbar;
}

export const ToolBarComponent: React.FC<ToolBarComponentProps> = ({
  toolBar,
}) => {
  const { openModal, setOpenModal } = useModalContext();

  const handleButtonRefreshClick = () => {
    console.log('Refresh');
  };

  const handleButtonDownloadClick = () => {
    console.log('Download');
  };

  const handleButtonUploadClick = () => {
    console.log('Upload');
  };

  return (
    <div className="flex gap-x-2">
      {toolBar.buttonNew.show && (
        <Button
          color={toolBar.buttonNew.colorStyle}
          size="sm"
          onClick={() => setOpenModal(!openModal)}
        >
          <FontAwesomeIcon
            icon={toolBar.buttonNew.icon as IconName}
            className="mr-2 h-4 w-4"
          />
          {toolBar.buttonNew.text}
        </Button>
      )}
      {toolBar.buttonRefresh.show && (
        <Button
          color={toolBar.buttonRefresh.colorStyle}
          size="sm"
          onClick={handleButtonRefreshClick}
        >
          <FontAwesomeIcon
            icon={toolBar.buttonRefresh.icon as IconName}
            className="mr-2 h-4 w-4"
          />
          {toolBar.buttonRefresh.text}
        </Button>
      )}
      {toolBar.buttonDownload.show && (
        <Button
          color={toolBar.buttonDownload.colorStyle}
          size="sm"
          onClick={handleButtonDownloadClick}
        >
          <FontAwesomeIcon
            icon={toolBar.buttonDownload.icon as IconName}
            className="mr-2 h-4 w-4"
          />
          {toolBar.buttonDownload.text}
        </Button>
      )}
      {toolBar.buttonUpload.show && (
        <Button
          color={toolBar.buttonUpload.colorStyle}
          size="sm"
          onClick={handleButtonUploadClick}
        >
          <FontAwesomeIcon
            icon={toolBar.buttonUpload.icon as IconName}
            className="mr-2 h-4 w-4"
          />
          {toolBar.buttonUpload.text}
        </Button>
      )}
    </div>
  );
};
