export enum ElementType {
  FORM_TITLE = 'formtitle',
  TEXT_BOX = 'textbox',
  TEXTAREA = 'textarea',
  DROPDOWN = 'dropdown',
  CHECKBOX = 'checkbox',
  BUTTON = 'button',
  FILE_UPLOAD = 'fileupload',
  LINKED_TEXT = 'linkedtext',
  TEXT = 'text',
  ICON_TEXT = 'icontext',
  PARAGRAPH = 'paragraph',
  LINKED_FILE = 'linkedfile',
  LINKED_FILE_LIST = 'linkedfilelist',
  LINKED_FILE_ITEM = 'linkedfileitem',
  ULIST = 'ulist',
  ULIST_ITEM = 'ulistitem',
  TABS = 'tabs',
  TAB = 'tab',
  TAB_HEADER = 'tabheader',
  TAB_HEADER_ALERT = 'tabheaderalert',
  SECTION = 'section',
  LABEL = 'label',
  DATERANGE = 'daterange',
}
