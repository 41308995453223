import { ModalComponent } from 'components/Modals/ModalComponent';
import { Loader } from 'components/Shared/Loader';
import { DatatableSection } from 'components/Table/DatatableSection';
import { ToolBarComponent } from 'components/Table/ToolBarComponent';
import useRecords from 'hooks/useRecords';
import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { Page } from 'types/PageTypes';

interface Sort {
  direction: string;
  by: string;
}

interface ParamsContextValue {
  pageSize: number;
  setPageSize: Dispatch<SetStateAction<number>>;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  setSearch: Dispatch<SetStateAction<string>>;
  sort: Sort;
  setSort: Dispatch<SetStateAction<Sort>>;
  filters: any; // TODO - set type
  setFilters: Dispatch<SetStateAction<any>>;
}

export const ParamsContext = createContext<ParamsContextValue | undefined>(
  undefined,
);

interface ModalContextValue {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  onSubmitModal: () => void;
}

export const ModalContext = createContext<ModalContextValue | undefined>(
  undefined,
);

interface Datatabled_v1Props {
  page: Page;
}

export const Datatabled_v1: React.FC<Datatabled_v1Props> = ({ page }) => {
  if (!page.datatable) {
    return <>Error loading datatable.</>;
  }

  const [openModal, setOpenModal] = useState(false);

  const [pageSize, setPageSize] = useState(
    page.datatable.settings.pagination.size.defaultSelection,
  );
  const [pageNumber, setPageNumber] = useState(
    page.datatable.settings.pagination.pageNumber,
  );

  const [search, setSearch] = useState('');

  const [sort, setSort] = useState<Sort>({
    direction: page.datatable?.settings.sortation.direction,
    by: page.datatable?.settings.sortation.value,
  });
  const [filters, setFilters] = useState({});

  const {
    data: records,
    isError,
    isLoading,
  } = useRecords({
    resource: page.resource,
    search,
    sort,
    paging: { pageNumber, pageSize },
    filters,
  });

  const onSubmitModal = () => {
    console.log('Submit');
  };

  return (
    <>
      <ModalContext.Provider
        value={{
          openModal,
          setOpenModal,
          onSubmitModal,
        }}
      >
        <div className="p-8 xl:px-12 flex-grow overflow-x-auto transition-all duration-300">
          <div className="flex justify-between items-center w-full">
            <h1 className="text-xl md:text-2xl xl:text-3xl font-bold text-gray-700">
              {page.title}
            </h1>
            {page.toolBar?.show && <ToolBarComponent toolBar={page.toolBar} />}
          </div>
          <div className="mt-8 flex flex-col space-y-5">
            {isError ? (
              <>Error loading data</>
            ) : isLoading ? (
              <Loader size="xl" />
            ) : (
              records && (
                <ParamsContext.Provider
                  value={{
                    pageSize,
                    setPageSize,
                    pageNumber,
                    setPageNumber,
                    setSearch,
                    sort,
                    setSort,
                    filters,
                    setFilters,
                  }}
                >
                  <DatatableSection
                    datatable={page.datatable}
                    records={records}
                  />
                </ParamsContext.Provider>
              )
            )}
          </div>
        </div>
        <ModalComponent />
      </ModalContext.Provider>
    </>
  );
};
