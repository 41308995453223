import { useMutation } from '@tanstack/react-query';
import httpClient from 'services/httpClient';

const CANCEL_URL = 'support/tickets';

export enum Action {
  CANCEL = 'cancel',
  REOPEN = 'reopen',
}

interface TicketActionParams {
  supportTicketId: string;
  action: Action;
}

const useTicketAction = () => {
  return useMutation({
    mutationFn: async (params: TicketActionParams) => {
      const { data } = await httpClient.post(
        `${CANCEL_URL}/${params.supportTicketId}/action-${params.action}`,
      );

      return data;
    },
    // TODO: onSuccess show Toas and invalidateQueries for useRecords
  });
};

export default useTicketAction;
