import { Default } from 'pages/Default';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Connect } from 'templates/Connect';
import { Datatabled_v1 } from 'templates/Datatabled_v1';
import { Error } from 'templates/Error';
import { Maintenance } from 'templates/Maintenance';
import { Page, PageTemplateType } from 'types/PageTypes';

interface RouterProps {
  pages: Page[];
  defaultPage: string;
}

export const Router = ({ pages, defaultPage }: RouterProps) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Default />}>
          {pages.map(page => {
            switch (page.templateType) {
              case PageTemplateType.DATATABLE_V1:
                return (
                  <Route
                    key={page.key}
                    path={page.key}
                    element={<Datatabled_v1 page={page} />}
                  />
                );
              case PageTemplateType.CONNECT:
                return (
                  <Route
                    key={page.key}
                    path={page.key}
                    element={<Connect page={page} />}
                  />
                );
              case PageTemplateType.ERROR:
                return (
                  <Route
                    key={page.key}
                    path={page.key}
                    element={<Error page={page} />}
                  />
                );
              case PageTemplateType.MAINTENANCE:
                return (
                  <Route
                    key={page.key}
                    path={page.key}
                    element={<Maintenance page={page} />}
                  />
                );
              //TODO: Implement maintenance and disconnect templates
              // Left console log as a reminder
              default:
                console.log(
                  'Not handled template type in router!',
                  page.templateType,
                );
                break;
            }
          })}
          <Route path="/" element={<Navigate to={defaultPage} />} />
        </Route>
        <Route path="*" element={<Navigate to={defaultPage} />} />
      </Routes>
    </BrowserRouter>
  );
};
