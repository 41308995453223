import { Label, Textarea } from 'flowbite-react';
import { Tab } from 'types/element-types/TabType';

interface TabProblemBodyProps {
  tab: Tab;
}

export const TabProblemBody: React.FC<TabProblemBodyProps> = ({ tab }) => {
  return (
    <>
      {tab && (
        <div>
          <div className="flex justify-between items-center mb-4">
            <p className="font-bold">{tab.title?.value}</p>
            <div className="flex items-center space-x-1 text-sm">
              {/* <FontAwesomeIcon icon={tab.status?.icon as IconProp} /> */}
              <span>{tab.status?.value}</span>
            </div>
          </div>
          <hr />
          <div className="mt-4 space-y-4">
            <div className="flex justify-between">
              <div>
                {tab.type?.label?.show && <Label value={tab.type.label.text} />}
                <p className="font-bold">{tab.type?.value}</p>
              </div>
              <div>
                {tab.problemType?.label?.show && (
                  <Label value={tab.problemType.label.text} />
                )}
                <p className="font-bold">{tab.problemType?.value}</p>
              </div>
              <div>
                {tab.priority?.label?.show && (
                  <Label value={tab.priority.label.text} />
                )}
                <p className="font-bold">{tab.priority?.value}</p>
              </div>
              <div>
                {tab.created?.label?.show && (
                  <Label value={tab.created.label.text} />
                )}
                <p className="font-bold">{tab.created?.value}</p>
              </div>
            </div>
            <div>
              <div>
                {tab.createdBy?.label?.show && (
                  <p className="text-sm text-gray-500">
                    <Label value={tab.createdBy.label.text} />
                  </p>
                )}
                <p className="font-bold">{tab.createdBy?.value}</p>
              </div>
            </div>
            <div>
              {tab.description?.label.show && (
                <Label value={tab.description.label.text} />
              )}
              <Textarea
                readOnly
                value={tab.description?.value}
                className="resize-none focus:border-gray-300 focus:ring-0"
              />
            </div>
            <div>
              {tab.attachments?.label.show && (
                <Label value={tab.attachments.label.text} />
              )}
              <div className="flex gap-4 text-sm mt-2">
                {tab.attachments?.items.map((item, index) => (
                  <p
                    className="text-xs text-blue-600 underline cursor-pointer"
                    key={index}
                  >
                    {item.value}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
