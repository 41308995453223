import { QueryClient } from '@tanstack/react-query';

const DEFAULT_STALE_TIME = Infinity;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_STALE_TIME,
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

export default queryClient;
