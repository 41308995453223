import useSiteLayout from 'hooks/site/useSiteLayout';
import { Router } from 'router';

export const App = () => {
  const { data } = useSiteLayout();

  if (!data) return <></>;

  return (
    <Router
      pages={data.layout.pages}
      defaultPage={data.layout.defaultPageKey}
    />
  );
};
