import { useContext } from 'react';
import { ParamsContext } from 'templates/Datatabled_v1';

export const useParamsContext = () => {
  const context = useContext(ParamsContext);

  if (context === undefined) {
    throw new Error('useParamsContext must be inside a ParamsProvider');
  }

  return context;
};
