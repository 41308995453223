import axios from 'axios';
import { API_BASE_URL, LEGACY_PORTAL_URL } from 'env';

import client from './client';

const httpClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Language': navigator.languages[0],
    'X-Timezone-Offset': new Date().getTimezoneOffset(),
    'X-FP': client.getFingerprint(),
    'X-Environment': 'sandbox',
  },
});

httpClient.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

httpClient.interceptors.response.use(
  config => {
    return config;
  },
  error => {
    console.error(
      'internalCode=',
      error.response.data['internalCode'],
      'message=',
      error.response.data['message'],
    );

    localStorage.clear();
    window.location.replace(LEGACY_PORTAL_URL);

    return error;
  },
);

export default httpClient;
