import { Pagination } from 'flowbite-react';
import { useParamsContext } from 'hooks/useParamsContext';
import { customThemePagination } from 'theme-utils/customThemes';
import { PaginationSettings } from 'types/ConnectTypes';

interface PaginationComponentProps {
  total: number;
  paginationSettings: PaginationSettings;
}

export const PaginationComponent: React.FC<PaginationComponentProps> = ({
  total,
  paginationSettings,
}) => {
  const { pageSize, setPageSize, pageNumber, setPageNumber } =
    useParamsContext();

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPageNumber(paginationSettings.pageNumber);
    setPageSize(parseInt(e.target.value, 10));
  };

  if (!paginationSettings.enabled) return null;

  const showingFrom = pageSize * pageNumber - pageSize + 1;
  const showingTo =
    pageSize * pageNumber > total ? total : pageSize * pageNumber;

  const totalDisplay = <span className="font-bold text-gray-900">{total}</span>;

  return (
    <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row justify-between items-center px-5 py-5">
      <div className="flex items-center space-x-3">
        <span className="text-xs font-normaln text-gray-500">
          Showing{' '}
          <span className="font-bold text-gray-900">
            {showingFrom}-{showingTo}
          </span>{' '}
          of {totalDisplay}
        </span>
        <select
          value={pageSize}
          onChange={handlePageSizeChange}
          className="text-xs ml-3 px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-100 hover:text-gray-700 focus:ring-0 focus:ring-offset-0"
        >
          {paginationSettings.size.options.map((value, index) => (
            <option value={value} key={index}>
              Show {value}
            </option>
          ))}
        </select>
      </div>
      <Pagination
        className="text-sm"
        theme={customThemePagination}
        currentPage={pageNumber}
        onPageChange={page => {
          setPageNumber(page);
        }}
        totalPages={Math.ceil(total / pageSize)}
      />
    </div>
  );
};
