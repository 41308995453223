import { User } from './User';

interface RenderSettings {
  type: string;
  alignment: string;
  function: any;
}

interface ColumnWidth {
  type: string;
  min: number;
  max: number;
}

export interface Column {
  id: string;
  title: string | null;
  visible: boolean;
  width: ColumnWidth;
  sortation: SortationSettings;
  data: string;
  render: RenderSettings;
}

interface Shortcut {
  type: string;
  text: string;
}

interface Option {
  id: string;
  text: string;
}

interface DateRange {
  format: string;
  from: string;
  to: string;
}

interface ControlLabel {
  elementType: string;
  key: string;
  show: boolean;
  text: string;
  description: string | null;
}

export interface FilterElement {
  elementType: string;
  key: string;
  type: string;
  label: ControlLabel;
  options?: Option[];
  shortcuts?: {
    show: boolean;
    options: Shortcut[];
  };
  defaultSelection?: DateRange;
  selection?: DateRange;
}

export interface FilterSettings {
  show: boolean;
  type: string;
  aside: {
    key: string;
    elements: FilterElement[];
  };
}

interface SearchSettings {
  show: boolean;
  blur: string;
}

interface SortationSettings {
  enabled: boolean;
  direction: string;
  value: string;
}

interface PaginationSettings {
  enabled: boolean;
  pageNumber: number;
  size: {
    defaultSelection: number;
    options: number[];
  };
}

interface DatatableSettings {
  pagination: PaginationSettings;
  sortation: SortationSettings;
  search: SearchSettings;
  filters: FilterSettings;
  units: {
    singular: string;
    plural: string;
  };
  zeroRecordsMessage: string;
}

interface Title {
  show: boolean;
  text: string | null;
}

export interface Datatable {
  key: string;
  title: Title;
  settings: DatatableSettings;
  columns: Column[];
}

interface Action {
  type: string;
  key: string;
}

interface Button {
  show: boolean;
  text: string;
  icon: string;
  colorStyle: string;
  action: Action;
}

export interface Toolbar {
  show: boolean;
  buttonNew: Button;
  buttonRefresh: Button;
  buttonDownload: Button;
  buttonUpload: Button;
}

interface Content {
  title: string;
  description: string;
  advisory?: string;
  user?: User;
  reconnect?: string;
}

export enum PageTemplateType {
  ERROR = 'error',
  MAINTENANCE = 'maintenance',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  DATATABLE_V1 = 'datatabled_v1',
}

export enum PageThemeKey {
  INHERIT = 'inherit',
}

export interface Page {
  templateType: PageTemplateType;
  key: string;
  title: string;
  relativeName: string;
  resource: string;
  content?: Content;
  themeKey: PageThemeKey;
  toolBar?: Toolbar;
  datatable?: Datatable;
}

export interface PageResponse {
  page: Page;
}
