import { Label } from 'flowbite-react';
import { Tab } from 'types/element-types/TabType';

interface TabResolutionBodyProps {
  tab: Tab;
}

export const TabResolutionBody: React.FC<TabResolutionBodyProps> = ({
  tab,
}) => {
  return (
    <>
      {tab && (
        <div>
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center space-x-1 text-sm">
              <span>{tab.status?.value}</span>
            </div>
          </div>
          <hr />
          <div className="mt-4 space-y-4">
            <div className="flex justify-between">
              <div>
                {tab.details?.label.show && (
                  <Label value={tab.details.label.text} />
                )}
                <p className="font-bold">{tab.details?.value}</p>
              </div>
            </div>
            {tab.attachments && (
              <div>
                {tab.attachments.items.map((attachment, index) => (
                  <p
                    className="text-xs text-blue-600 underline cursor-pointer"
                    key={index}
                  >
                    {attachment.value}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
