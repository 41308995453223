import { useMutation } from '@tanstack/react-query';
import httpClient from 'services/httpClient';

const ADD_ATTACHMENTS_URL = 'support/tickets';

interface AddAttachmentsParams {
  supportTicketId: string;
  attachments: File[];
}

const useAddAttachments = () => {
  return useMutation({
    mutationFn: async (params: AddAttachmentsParams) => {
      const formData = new FormData();
      for (let i = 0; i < params.attachments.length; i++) {
        formData.append(`files[${i}]`, params.attachments[i]);
      }

      await httpClient.post<void>(
        `${ADD_ATTACHMENTS_URL}/${params.supportTicketId}/attachments`,
        formData,
      );
    },
  });
};

export default useAddAttachments;
