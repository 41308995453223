import { Modal, Tabs } from 'flowbite-react';
import { useViewRecordModalContext } from 'hooks/useViewRecordModalContext';
import { Tab } from 'types/element-types/TabType';
import { TabKeys } from 'types/enums/TabKeyEnum';
import { ViewRecordResponse } from 'types/ViewRecordTypes';

import { TabMessagesBody } from './TabMessagesBody';
import { TabMoreBody } from './TabMoreBody';
import { TabProblemBody } from './TabProblemBody';
import { TabReplyRequiredBody } from './TabReplyRequiredBody';
import { TabResolutionBody } from './TabResolutionBody';

interface ViewRecordModalProps {
  modalData?: ViewRecordResponse;
}

export const ViewRecordModal: React.FC<ViewRecordModalProps> = ({
  modalData,
}) => {
  const { openModal, setOpenModal } = useViewRecordModalContext();

  const renderTabBody = (tab: Tab) => {
    switch (tab.key) {
      case TabKeys.PROBLEM:
        return <TabProblemBody tab={tab} />;
      case TabKeys.MESSAGES:
        return <TabMessagesBody tab={tab} ticketId={modalData?.id} />;
      case TabKeys.MORE:
        return modalData && <TabMoreBody tab={tab} ticketId={modalData.id} />;
      case TabKeys.REPLYREQUIRED:
        return <TabReplyRequiredBody tab={tab} />;
      case TabKeys.RESOLUTION:
        return <TabResolutionBody tab={tab} />;
      default:
        break;
    }
  };

  return (
    <>
      {modalData && (
        <Modal dismissible show={openModal} onClose={() => setOpenModal(false)}>
          <Modal.Header>
            {modalData.title.show && (
              <p>
                {modalData.title.text}{' '}
                <span className="font-bold">{modalData.number}</span>
              </p>
            )}
          </Modal.Header>
          <Modal.Body>
            <Tabs.Group>
              {modalData.tabs.tabs.map(tab => (
                <Tabs.Item
                  title={
                    <div className="relative">
                      {tab.header.title}
                      {tab.header.alert && (
                        <div className="absolute -top-5 -right-5 bg-red-800 w-5 h-5 rounded-full text-white">
                          <span>!</span>
                        </div>
                      )}
                    </div>
                  }
                  key={tab.key}
                  className="relative"
                >
                  {renderTabBody(tab)}
                </Tabs.Item>
              ))}
            </Tabs.Group>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
