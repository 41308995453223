import { TextInput } from 'flowbite-react';
import useDebounce from 'hooks/useDebounce';
import { useParamsContext } from 'hooks/useParamsContext';
import { useEffect, useState } from 'react';
import { HiSearch, HiX } from 'react-icons/hi';
import { customThemeTextInput } from 'theme-utils/customThemes';
import { Datatable } from 'types/PageTypes';
import { RecordsResponse } from 'types/RecordsTypes';

import { FilterComponent } from './FilterComponent';
import { PaginationComponent } from './PaginationComponent';
import { TableComponent } from './TableComponent';

interface DatatableProps {
  datatable: Datatable;
  records: RecordsResponse;
}

export const DatatableSection: React.FC<DatatableProps> = ({
  datatable,
  records,
}) => {
  const [searchText, setSearchText] = useState('');
  const { setSearch } = useParamsContext();

  const debouncedSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    setSearch(debouncedSearchText);
  }, [debouncedSearchText, setSearch]);

  const handleClearSearch = () => {
    setSearchText('');
    setSearch('');
  };

  return (
    <div className="w-full">
      <div className="bg-white shadow rounded-md">
        <div className="p-3">
          {datatable.title.show && (
            <h3 className="text-xl font-semibold text-gray-700 mb-3 ml-1">
              {datatable.title.text}
            </h3>
          )}
          <div className="flex items-center space-x-5">
            <div className="w-full relative">
              <TextInput
                theme={customThemeTextInput}
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                icon={HiSearch}
                type="text"
                placeholder="Search"
                className="w-full"
              />
              {searchText && (
                <HiX
                  onClick={handleClearSearch}
                  className="h-4 w-4 absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all duration-100"
                />
              )}
            </div>
            <FilterComponent dropdownSettings={datatable.settings.filters} />
          </div>
        </div>
        <TableComponent records={records.records} columns={datatable.columns} />
        <PaginationComponent
          total={records.total}
          paginationSettings={datatable.settings.pagination}
        />
      </div>
    </div>
  );
};
