import {
  Accordion,
  Avatar,
  Button,
  FileInput,
  Label,
  Textarea,
} from 'flowbite-react';
import useSendMessage from 'hooks/useSendMessage';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Tab } from 'types/element-types/TabType';

interface FormData {
  message: string;
  attachments: FileList;
}

interface TabMessagesBodyProps {
  tab: Tab;
  ticketId?: string;
}

export const TabMessagesBody: React.FC<TabMessagesBodyProps> = ({
  tab,
  ticketId,
}) => {
  const { mutate: sendMessage } = useSendMessage();

  const { register, handleSubmit } = useForm<FormData>();

  const handleMessageSend: SubmitHandler<FormData> = async data => {
    const requestBody = {
      body: data.message,
      attachments: Array.from(data.attachments).map(file => ({
        name: file.name,
        size: file.size,
      })),
    };
    if (ticketId) {
      sendMessage({ supportTicketId: ticketId, requestBody });
    }

    console.log(requestBody, ticketId);
  };
  return (
    <>
      {tab.messageList && (
        <Accordion>
          {tab.messageList.items.map(item => (
            <Accordion.Panel key={item.id}>
              <Accordion.Title className="w-full">
                <div className="flex items-center justify-between w-full">
                  <Avatar placeholderInitials={item.sender.initials} rounded>
                    {`${item.sender.fname} ${item.sender.lname}`}
                    <p className="text-xs text-gray-400">
                      {item.timeStamp.value}
                    </p>
                  </Avatar>
                </div>
              </Accordion.Title>
              <Accordion.Content>
                <Textarea
                  readOnly
                  value={item.body.value || ''}
                  className="resize-none focus:border-gray-300 focus:ring-0"
                />
                {item.attachments && (
                  <div className="flex gap-4 text-sm mt-2">
                    {item.attachments.items.map((item, index) => (
                      <p
                        className="text-xs text-blue-600 underline cursor-pointer"
                        key={index}
                      >
                        {item.value}
                      </p>
                    ))}
                  </div>
                )}
              </Accordion.Content>
            </Accordion.Panel>
          ))}
        </Accordion>
      )}
      <hr className="my-5" />
      {tab.sendSection && (
        <form onSubmit={handleSubmit(handleMessageSend)}>
          <div className="space-y-5">
            <Textarea
              {...register('message', { required: 'Message is required' })}
              className="resize-none"
              placeholder={tab.sendSection.body.blur}
              disabled={tab.sendSection.body.disabled}
              rows={3}
            />
            <div>
              {tab.sendSection.attachments.label.show && (
                <Label value={tab.sendSection.attachments.label.text} />
              )}
              <FileInput
                {...register('attachments')}
                multiple={tab.sendSection.attachments.type === 'multi'}
                disabled={tab.sendSection.attachments.disabled}
                helperText={tab.sendSection.attachments.label.description}
              />
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <Button
              disabled={tab.sendSection.send.disabled}
              className="w-[200px]"
              color="blue"
              type="submit"
            >
              {tab.sendSection.send.text}
            </Button>
          </div>
        </form>
      )}
    </>
  );
};
