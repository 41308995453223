import { useQuery } from '@tanstack/react-query';
import httpClient from 'services/httpClient';
import { SiteLayoutResponse } from 'types/SiteLayout';

export const SITE_LAYOUT_URL = '/site/layout';

const useSiteLayout = () => {
  return useQuery({
    queryKey: [SITE_LAYOUT_URL],
    queryFn: async () => {
      const { data } =
        await httpClient.get<SiteLayoutResponse>(SITE_LAYOUT_URL);
      return data;
    },
  });
};

export default useSiteLayout;
