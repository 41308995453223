import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';

interface SidebarMenuItem {
  type: string;
  icon: string;
  text: string;
  page?: {
    key: string;
    resource: string;
  };
}

interface SidebarMenuItemProps extends SidebarMenuItem {
  isToggled: boolean;
  items?: SidebarMenuItem[];
}

export const SidebarMenuItem = (data: SidebarMenuItemProps) => {
  const location = useLocation();
  const isActive = location.pathname === '/' + data.page?.key;

  const renderMenuItem = (item: SidebarMenuItem, index: number) => (
    <SidebarMenuItem key={index} isToggled={data.isToggled} {...item} />
  );

  if (data.type === 'group') {
    return (
      <div>
        <div
          className={`${
            !data.isToggled && 'justify-center'
          } flex items-center gap-x-2 px-2 py-2 rounded-md whitespace-nowrap cursor-pointer transition-all duration-100 ease opacity-90 hover:opacity-100`}
        >
          <FontAwesomeIcon icon={data.icon as IconName} />
          {data.isToggled && (
            <span className="text-sm pointer-events-none transition-all duration-100 ease-in">
              {data.text}
            </span>
          )}
        </div>
        {data.items?.map(renderMenuItem)}
      </div>
    );
  } else if (data.isToggled && data.page) {
    return (
      <Link
        to={data.page.key}
        className={`ml-3 flex items-center gap-x-2 px-2 py-2 rounded-md whitespace-nowrap cursor-pointer transition-all duration-100 ease opacity-90 hover:opacity-100 ${
          isActive ? 'opacity-100 font-bold' : ''
        }`}
      >
        <FontAwesomeIcon icon={data.icon as IconName} />
        {data.isToggled && (
          <span className="text-sm pointer-events-none transition-all duration-100 ease-in">
            {data.text}
          </span>
        )}
      </Link>
    );
  }

  return null;
};
