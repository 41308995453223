import { Button, Label } from 'flowbite-react';
import { useParamsContext } from 'hooks/useParamsContext';
import { useState } from 'react';
import { HiFilter } from 'react-icons/hi';
import { ElementType } from 'types/enums/ElementTypeEnum';
import { FilterElement, FilterSettings } from 'types/PageTypes';

import { DatepickerComponent } from '../Shared/DatepickerComponent';
import { SelectComponent } from '../Shared/SelectComponent';

interface SelectedValues {
  [key: string]: string[] | DateType;
}

export interface DateType {
  to: string | null;
  from: string | null;
}

interface FilterComponentProps {
  dropdownSettings: FilterSettings;
}

export const FilterComponent: React.FC<FilterComponentProps> = ({
  dropdownSettings,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { filters, setFilters } = useParamsContext();

  // TODO - use react hook form
  const handleFieldChange = (
    elementKey: string,
    values: string[] | DateType,
  ) => {
    setFilters((prevValues: SelectedValues) => {
      const updatedValues: SelectedValues = { ...prevValues };

      if (Array.isArray(values) && values.length > 0) {
        updatedValues[elementKey] = values;
      } else if (!Array.isArray(values) && Object.keys(values).length > 0) {
        updatedValues[elementKey] = values;
      } else {
        delete updatedValues[elementKey];
      }

      return updatedValues;
    });
  };

  const handleClear = () => {
    setFilters({});
  };

  const renderElement = (element: FilterElement, index: number) => {
    switch (element.elementType) {
      case ElementType.DATERANGE:
        return (
          <div key={index} className="mt-2">
            <Label htmlFor={element.key} value={element.label.text} />
            <DatepickerComponent
              key={`${element.key}-${JSON.stringify(filters[element.key])}`}
              dateValue={filters[element.key]}
              showShortcuts={element.shortcuts?.show || false}
              dateFormat={element.defaultSelection?.format}
              onDatepickerValueChange={(formatedDate: DateType) =>
                handleFieldChange(element.key, formatedDate)
              }
            />
          </div>
        );
      case ElementType.DROPDOWN:
        return (
          <div key={index} className="mt-2">
            <Label htmlFor={element.key} value={element.label.text} />
            {element.options && element.options.length > 0 && (
              <SelectComponent
                key={`${element.key}-${JSON.stringify(filters[element.key])}`}
                values={filters[element.key]}
                options={element.options}
                isMulti={true}
                onChange={(values: string[]) =>
                  handleFieldChange(element.key, values)
                }
              />
            )}
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <div className="relative">
      <Button
        size="xs"
        color="blue"
        className="whitespace-nowrap"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <HiFilter className="mr-2 h-5 w-5" />
        Filters
      </Button>
      {dropdownOpen && (
        <div className="absolute right-0 mt-2 bg-white z-50 w-max rounded shadow">
          <div className="p-3 pb-4 min-w-[300px]">
            <div className="flex justify-between items-center mb-3">
              <span className="font-medium text-md">Filters</span>
              <span
                className="font-light opacity-80 hover:opacity-100 cursor-pointer text-sm"
                onClick={handleClear}
              >
                Clear
              </span>
            </div>
            <hr />
            <form action="">
              {dropdownSettings.aside.elements.map((element, index) =>
                renderElement(element, index),
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
