import { Avatar } from 'flowbite-react';
import { User } from 'types/User';

interface SidebarProfileProps {
  isToggled: boolean;
  userProfile: User;
}

export const SidebarProfile = ({
  isToggled,
  userProfile,
}: SidebarProfileProps) => {
  return (
    <div className={`my-5 w-full h-10 ${isToggled && 'flex'}`}>
      <Avatar
        placeholderInitials={`${userProfile.fname.charAt(
          0,
        )}${userProfile.lname.charAt(0)}`}
        rounded
        className="cursor-pointer transition-all duration-300 opacity-90 hover:opacity-100"
      >
        {isToggled && (
          <div className="font-medium">
            <div className="whitespace-nowrap">{`${userProfile.fname} ${userProfile.lname}`}</div>
            <div className="text-sm text-gray-500">{userProfile.title}</div>
          </div>
        )}
      </Avatar>
    </div>
  );
};
