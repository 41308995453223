import { Button } from 'flowbite-react';
import useTicketAction from 'hooks/useTicketAction';
import { Action } from 'hooks/useTicketAction';
import { useViewRecordModalContext } from 'hooks/useViewRecordModalContext';
import { Tab } from 'types/element-types/TabType';

interface TabMoreBodyProps {
  ticketId: string;
  tab: Tab;
}

export const TabMoreBody: React.FC<TabMoreBodyProps> = ({ tab, ticketId }) => {
  const { setOpenModal } = useViewRecordModalContext();
  const { mutate: ticketAction } = useTicketAction();

  const handleAction = (action: Action) => {
    ticketAction(
      { supportTicketId: ticketId, action },
      {
        onSuccess: () => {
          // TODO: Show Toast or some notification
          setOpenModal(false);
        },
      },
    );
  };

  return (
    <>
      {tab.reopen && (
        <Button
          disabled={tab.reopen.disabled}
          color={tab.reopen.colorStyle}
          className="w-full"
          onClick={() => handleAction(Action.REOPEN)}
        >
          {tab.reopen.text}
        </Button>
      )}
      {tab.cancel && (
        <Button
          disabled={tab.cancel.disabled}
          color={tab.cancel.colorStyle}
          className="w-full"
          onClick={() => handleAction(Action.CANCEL)}
        >
          {tab.cancel.text}
        </Button>
      )}
    </>
  );
};
