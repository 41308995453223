import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Table, Tooltip } from 'flowbite-react';
import { useViewRecordModalContext } from 'hooks/useViewRecordModalContext';
import { HiDocumentRemove, HiEye, HiPencil } from 'react-icons/hi';

interface CellComponentProps {
  cellValue: any; //TODO: Change to RecordField once response moved to array
  index: number;
  id: string;
  recordId: string;
}

export const TableCellComponent: React.FC<CellComponentProps> = ({
  cellValue,
  index,
  id,
  recordId,
}) => {
  const { handleViewRecord } = useViewRecordModalContext();

  if (typeof cellValue === 'object' && cellValue !== null) {
    if (id === 'action') {
      return (
        <Table.Cell key={index}>
          <Dropdown
            label
            dismissOnClick={false}
            renderTrigger={() => (
              <span className="cursor-pointer p-2">
                <FontAwesomeIcon icon={'ellipsis'} />
              </span>
            )}
          >
            {cellValue.view && (
              <Dropdown.Item
                icon={HiEye}
                onClick={() => handleViewRecord(recordId)}
              >
                View
              </Dropdown.Item>
            )}
            {cellValue.edit && (
              <Dropdown.Item icon={HiPencil}>Edit</Dropdown.Item>
            )}
            {cellValue.remove && (
              <Dropdown.Item icon={HiDocumentRemove}>Remove</Dropdown.Item>
            )}
          </Dropdown>
        </Table.Cell>
      );
    }
    if (Object.prototype.hasOwnProperty.call(cellValue, 'type')) {
      switch (cellValue.type) {
        case 'span':
          return (
            <Table.Cell
              key={index}
              className={`text-${cellValue.span.color}-900`}
            >
              {cellValue.span.value}
            </Table.Cell>
          );
        default:
          return (
            <Table.Cell
              key={index}
              className={`text-${cellValue.text.color}-900`}
            >
              {cellValue.text.value}
            </Table.Cell>
          );
      }
    }
    return (
      <Table.Cell key={index} className={`text-${cellValue.color}-500`}>
        {cellValue.tooltip ? (
          <Tooltip content={cellValue.tooltip}>{cellValue.text}</Tooltip>
        ) : (
          cellValue.text
        )}
      </Table.Cell>
    );
  }
  return <Table.Cell key={index}></Table.Cell>;
};
