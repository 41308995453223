import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ViewRecordModal } from 'components/ViewRecord/ViewRecordModal';
import { Table } from 'flowbite-react';
import { useParamsContext } from 'hooks/useParamsContext';
import useViewTicket from 'hooks/useViewTicket';
import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { SortDirection } from 'types/enums/SortDirectionEnum';
import { Column } from 'types/PageTypes';
import { Record } from 'types/RecordsTypes';

import { TableCellComponent } from './TableCellComponent';

interface ViewRecordModalContext {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  handleViewRecord: (id: string) => void;
}

export const ViewRecordModalContext = createContext<
  ViewRecordModalContext | undefined
>(undefined);

interface TableComponentProps {
  records: Record[];
  columns: Column[];
}

export const TableComponent: React.FC<TableComponentProps> = ({
  records,
  columns,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState('');

  const { sort, setSort } = useParamsContext();

  const { data: viewRecordData } = useViewTicket(selectedRecordId);

  const handleViewRecord = (id: string) => {
    setSelectedRecordId(id);
    setOpenModal(!openModal);
  };

  const handleSort = (id: string) => {
    setSort(prevSort => ({
      direction:
        prevSort.by === id
          ? prevSort.direction === SortDirection.ASCENDING
            ? SortDirection.DESCENDING
            : SortDirection.ASCENDING
          : SortDirection.ASCENDING,
      by: id,
    }));
  };

  const getSortIcons = (id: string) => {
    return (
      <div className="flex flex-col ml-2">
        <FontAwesomeIcon
          icon={'angle-up'}
          className={`opacity-${
            sort.by === id && sort.direction === SortDirection.ASCENDING
              ? '100'
              : '20'
          }`}
        />
        <FontAwesomeIcon
          icon={'angle-down'}
          className={`opacity-${
            sort.by === id && sort.direction === SortDirection.DESCENDING
              ? '100'
              : '20'
          }`}
        />
      </div>
    );
  };

  return (
    <>
      <ViewRecordModalContext.Provider
        value={{ openModal, setOpenModal, handleViewRecord }}
      >
        <div className="w-full">
          <div className="bg-white shadow rounded-md p-3">
            <div className="overflow-x-auto overflow-y-auto max-h-[500px]">
              <Table className="text-xs whitespace-nowrap" hoverable>
                <Table.Head className="sticky top-0">
                  {columns
                    .filter(columns => columns.visible)
                    .map(column => (
                      <Table.HeadCell
                        key={column.id}
                        className={`${
                          column.sortation.enabled && 'cursor-pointer'
                        }`}
                        onClick={() => handleSort(column.id)}
                      >
                        <div className="flex items-center">
                          {column.title}
                          {column.sortation.enabled && getSortIcons(column.id)}
                        </div>
                      </Table.HeadCell>
                    ))}
                </Table.Head>
                <Table.Body className="divide-y">
                  {records.map((record, index) => (
                    <Table.Row
                      key={index}
                      className="bg-white whitespace-nowrap"
                    >
                      {columns
                        .filter(columns => columns.visible)
                        .map((column, index) => (
                          <TableCellComponent
                            key={`${record.id}-${column.id}`}
                            cellValue={record[column.id]}
                            index={index}
                            id={column.id}
                            recordId={record.id}
                          />
                        ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
        <ViewRecordModal modalData={viewRecordData} />
      </ViewRecordModalContext.Provider>
    </>
  );
};
