import { CustomFlowbiteTheme } from 'flowbite-react';

export const customTheme: CustomFlowbiteTheme = {
  button: {
    color: {
      blue: 'text-white bg-blue-600 border border-transparent enabled:hover:bg-blue-700 focus:ring-0 focus:ring-transparent dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-transparent',
      darkblue:
        'text-white bg-blue-800 border border-transparent enabled:hover:bg-blue-900 focus:ring-0 focus:ring-transparent dark:bg-green-800 dark:hover:bg-green-900 dark:focus:ring-transparent',
      green:
        'text-white bg-green-600 border border-transparent enabled:hover:bg-green-700 focus:ring-0 focus:ring-transparent dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-transparent',
      darkgreen:
        'text-white bg-green-800 border border-transparent enabled:hover:bg-green-900 focus:ring-0 focus:ring-transparent dark:bg-green-800 dark:hover:bg-green-900 dark:focus:ring-transparent',
      red: 'text-white bg-red-600 border border-transparent enabled:hover:bg-red-700 focus:ring-0 focus:ring-transparent dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-transparent',
      darkred:
        'text-white bg-red-800 border border-transparent enabled:hover:bg-red-900 focus:ring-0 focus:ring-transparent dark:bg-red-800 dark:hover:bg-red-900 dark:focus:ring-transparent',
    },
  },
  tab: {
    tablist: {
      tabitem: {
        base: 'flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:ring-0 focus:ring-blue-300 focus:outline-none',
        styles: {
          default: {
            active: {
              on: 'bg-gray-100 text-blue-600 dark:bg-gray-800 dark:text-blue-500',
            },
          },
        },
      },
    },
  },
  spinner: {
    base: 'inline animate-spin text-gray-200',
    color: {
      black: 'fill-black',
    },
  },
  accordion: {
    content: {
      base: 'py-3 px-3 last:rounded-b-lg dark:bg-gray-900 first:rounded-t-lg',
    },
    title: {
      base: 'flex w-full items-center justify-between first:rounded-t-lg last:rounded-b-lg py-3 px-4 text-left font-medium text-gray-500 dark:text-gray-400',
      flush: {
        off: 'hover:bg-gray-50 focus:ring-0 focus:ring-gray-200 dark:hover:bg-gray-800 dark:focus:ring-gray-800',
        on: 'bg-transparent dark:bg-transparent',
      },
      open: {
        off: '',
        on: 'text-gray-900 bg-gray-50 dark:bg-gray-800 dark:text-white',
      },
    },
  },
  textarea: {
    colors: {
      gray: 'bg-gray-50 border-gray-300 text-gray-900 focus:border-gray focus:ring-0',
    },
  },
  fileInput: {
    field: {
      input: {
        colors: {
          gray: 'border-gray focus:border-gray focus:ring-0',
        },
      },
    },
  },
};

export const customThemeButton: CustomFlowbiteTheme['button'] = {
  color: {
    green:
      'text-white bg-green-700 border border-transparent enabled:hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800',
  },
};

export const customThemePagination: CustomFlowbiteTheme['pagination'] = {
  pages: {
    base: 'xs:mt-0 inline-flex items-center -space-x-px',
    previous: {
      base: 'ml-0 rounded-l-lg border border-gray-300 bg-white py-1 px-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white',
    },
    next: {
      base: 'rounded-r-lg border border-gray-300 bg-white py-1 px-3 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white',
    },
    selector: {
      base: 'w-8 border border-gray-300 bg-white py-1 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white',
      active:
        'bg-cyan-50 text-cyan-600 hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white',
    },
  },
};

export const customThemeSelect: CustomFlowbiteTheme['select'] = {
  field: {
    select: {
      colors: {
        gray: 'border-gray focus:border-gray focus:ring-0',
      },
    },
  },
};

export const customThemeTextInput: CustomFlowbiteTheme['textInput'] = {
  field: {
    input: {
      colors: {
        gray: 'bg-gray-50 border-gray-300 text-gray-900 focus:border-gray focus:ring-0',
      },
    },
  },
};
