import { useQuery } from '@tanstack/react-query';
import httpClient from 'services/httpClient';
import { NewTicketResponse } from 'types/NewTicketTypes';

const NEW_TICKET_URL = 'support/tickets/new';

const useNewTicket = () => {
  return useQuery({
    queryKey: [NEW_TICKET_URL],
    queryFn: async () => {
      const { data } = await httpClient.get<NewTicketResponse>(NEW_TICKET_URL);
      return data;
    },
  });
};

export default useNewTicket;
