import Select from 'react-select';

interface ReactSelectOption {
  value: string;
  label: string;
}

interface Option {
  id: string;
  text: string;
}

interface SelectComponentProps {
  options: Option[];
  values: string[];
  isMulti?: boolean;
  isSearchable?: boolean;
  onChange?: (selectedValues: string[]) => void;
}

export const SelectComponent: React.FC<SelectComponentProps> = ({
  options,
  values = [],
  isMulti = false,
  isSearchable = false,
  onChange,
}) => {
  const mappedOptions: ReactSelectOption[] = options.map((option: Option) => ({
    value: option.id,
    label: option.text,
  }));

  const handleChange = (selectedOptions: any) => {
    const selectedValues = selectedOptions.map(
      (option: ReactSelectOption) => option.value,
    );

    onChange && onChange(selectedValues);
  };

  return (
    <Select
      defaultValue={mappedOptions.filter(option =>
        values.includes(option.value),
      )}
      onChange={handleChange}
      options={mappedOptions}
      isMulti={isMulti}
      isSearchable={isSearchable}
      className="text-xs mt-1"
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          borderRadius: '0.5rem',
          backgroundColor: '#f9fafb',
          outline: 'none',
        }),
      }}
    />
  );
};
