import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoWhite from 'assets/images/AMX360LogoWhite.svg';
import logoWhiteSmall from 'assets/images/AMX360LogoWhiteSmall.svg';
import useSiteLayout from 'hooks/site/useSiteLayout';
import { useState } from 'react';

import { SidebarMenu } from './SidebarMenu';
import { SidebarProfile } from './SidebarProfile';

export const Sidebar = () => {
  const [isToggled, setIsToggled] = useState(false);
  const { data: siteLayout } = useSiteLayout();

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  if (!siteLayout) return <></>;

  return (
    <div
      className={`${
        isToggled ? 'min-w-[220px] ' : ''
      }bg-gray-900 text-white px-5 py-3 z-50 flex flex-col items-center shadow`}
    >
      {/* Sidebar header */}
      <div
        className={`flex w-full ${
          isToggled ? 'justify-end' : 'justify-center'
        }`}
      >
        <FontAwesomeIcon
          onClick={handleToggle}
          icon={isToggled ? 'chevron-left' : 'bars'}
          className="my-3 h-4 w-4 cursor-pointer opacity-80 hover:opacity-100 transition-all ease-in-out"
        />
      </div>
      {siteLayout.layout.userProfile && (
        <SidebarProfile
          isToggled={isToggled}
          userProfile={siteLayout.layout.userProfile.person}
        />
      )}
      {siteLayout.layout.menu && (
        <SidebarMenu isToggled={isToggled} menu={siteLayout.layout.menu} />
      )}
      <img
        src={isToggled ? logoWhite : logoWhiteSmall}
        alt="AMX360 Logo"
        className={`${isToggled ? 'h-8 mb-3' : 'h-5 mb-5'}`}
      />
    </div>
  );
};
