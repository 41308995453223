import { useMutation } from '@tanstack/react-query';
import { NewTicketInputs } from 'components/Modals/ModalComponent';
import httpClient from 'services/httpClient';

const SUBMIT_TICKET_URL = 'support/tickets/new-submit';

interface SubmitTicketResponse {
  id: string;
}

const useSubmitTicket = () => {
  return useMutation({
    mutationFn: async (params: NewTicketInputs) => {
      const { data } = await httpClient.post<SubmitTicketResponse>(
        SUBMIT_TICKET_URL,
        params,
      );

      return data;
    },
  });
};

export default useSubmitTicket;
