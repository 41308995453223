import logo from 'assets/images/AMX360Logo.svg';
import { Loader } from 'components/Shared/Loader';
import { useConnect } from 'hooks/auth/useConnect';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Page } from 'types/PageTypes';

interface ConnectProps {
  page: Page;
}

export const Connect: React.FC<ConnectProps> = ({ page }) => {
  const [searchParams] = useSearchParams();

  const appid = searchParams.get('appid');
  const sitebid = searchParams.get('sitebid');
  const ensbid = searchParams.get('ensbid');
  const ubid = searchParams.get('ubid');
  const sid = searchParams.get('sid');

  const { mutate } = useConnect();

  useEffect(() => {
    // Due to Strict Mode when second render happens, user is redirected
    // As a quick fix timeout 0 is applied
    const timeout = setTimeout(() => {
      if (appid && sitebid && ensbid && ubid && sid) {
        mutate({ appid, sitebid, ensbid, ubid, sid });
      } else {
        window.location.replace('https://cloudportal.amx360.com/');
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center gap-8 mt-14 text-gray-900">
      <div className="text-center md:space-y-2">
        <p className="text-2xl md:text-4xl font-black">{page.content?.title}</p>
        <p className="text-base md:text-xl tracking-normal md:tracking-widest font-semibold">
          {page.content?.description}
        </p>
      </div>
      <Loader className="m-5" size="xl" />
      <img src={logo} alt="Logo" className="h-8 md:h-10 mt-2" />
    </div>
  );
};
