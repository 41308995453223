import { keepPreviousData, useQuery } from '@tanstack/react-query';
import httpClient from 'services/httpClient';
import { RecordsResponse } from 'types/RecordsTypes';

interface RecordsParams {
  resource: string;
  search: string;
  sort: {
    direction: string;
    by: string;
  };
  paging: {
    pageNumber: number;
    pageSize: number;
  };
  filters: any; // TODO - set type
}

const useRecords = ({
  resource,
  search,
  sort,
  paging,
  filters,
}: RecordsParams) => {
  const RESOURCE_URL = `/${resource}/list`;

  return useQuery({
    queryKey: [RESOURCE_URL, resource, search, sort, paging, filters],
    queryFn: async () => {
      const { data } = await httpClient.post<RecordsResponse>(RESOURCE_URL, {
        search,
        sort,
        paging,
        filters,
      });

      return data;
    },
    placeholderData: keepPreviousData,
    staleTime: 0,
  });
};

export default useRecords;
