import { Spinner, SpinnerProps } from 'flowbite-react';

export const Loader = (props: SpinnerProps) => {
  return (
    <div className="text-center">
      <Spinner
        color="black"
        aria-label="Center-aligned spinner example"
        {...props}
      />
    </div>
  );
};
