import {
  Button,
  FileInput,
  Label,
  Modal,
  Select,
  Textarea,
  TextInput,
} from 'flowbite-react';
import useAddAttachments from 'hooks/useAddAttachments';
import { useModalContext } from 'hooks/useModalContext';
import useNewTicket from 'hooks/useNewTicket';
import useSubmitTicket from 'hooks/useSubmitTicket';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  customThemeSelect,
  customThemeTextInput,
} from 'theme-utils/customThemes';

interface CustomFile {
  name: string;
  size: number;
}

export interface NewTicketInputs {
  type: string;
  secondSelectValue: string;
  priority: string;
  nameTag: string;
  description: string;
  attachments: CustomFile[];
  attachmentsFiles: File[];
  [key: string]: string | CustomFile[];
}

export const ModalComponent = () => {
  const { openModal, setOpenModal } = useModalContext();

  const { data: newTicketModalLayout } = useNewTicket();
  const { mutate: submitNewTicket } = useSubmitTicket();
  const { mutate: addAttachments } = useAddAttachments();

  const { register, watch, setValue, reset, handleSubmit } =
    useForm<NewTicketInputs>();

  const onSubmit: SubmitHandler<NewTicketInputs> = data => {
    data.attachments = Array.from(data.attachmentsFiles).map(file => ({
      name: file.name,
      size: file.size,
    }));

    data[`${data.type}Type`] = data.secondSelectValue;

    submitNewTicket(data, {
      onSuccess: submitData => {
        addAttachments(
          {
            supportTicketId: submitData.id,
            attachments: data.attachmentsFiles,
          },
          {
            onSuccess: () => {
              setOpenModal(false);
              reset();
              setValue('problemType', '');
            },
          },
        );
      },
    });
  };

  const problemTypeValue = watch('type');

  const getSecondSelectOptions = () => {
    switch (problemTypeValue) {
      case 'shipment':
        return newTicketModalLayout?.shipmentType.options;
      case 'api':
        return newTicketModalLayout?.apiType.options;
      case 'edi':
        return newTicketModalLayout?.ediType.options;
      case 'reporting':
        return newTicketModalLayout?.reportingType.options;
      case 'portal':
        return newTicketModalLayout?.portalType.options;
      default:
        return [];
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    reset();
    setValue('type', '');
  };

  return (
    <>
      <Modal dismissible show={openModal} onClose={handleCloseModal}>
        {newTicketModalLayout && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header>
              {newTicketModalLayout.title.show && (
                <p>{newTicketModalLayout.title.text}</p>
              )}
            </Modal.Header>
            <Modal.Body>
              <div className="space-y-2">
                <div>
                  {newTicketModalLayout.type.label.show && (
                    <Label value={newTicketModalLayout.type.label.text} />
                  )}
                  <Select
                    theme={customThemeSelect}
                    className="mt-1"
                    {...register('type', { required: true })}
                  >
                    <option value="">Please select problem type</option>
                    {newTicketModalLayout.type.options.map((option, index) => (
                      <option value={option.id} key={index}>
                        {option.text}
                      </option>
                    ))}
                  </Select>
                </div>
                {problemTypeValue && (
                  <>
                    <div>
                      <Label htmlFor="secondSelectValue" value="Type" />
                      {problemTypeValue !== 'other' ? (
                        <>
                          <Select
                            theme={customThemeSelect}
                            className="my-1"
                            {...register('secondSelectValue', {
                              required: true,
                            })}
                          >
                            <option value="">Please select type</option>
                            {getSecondSelectOptions()?.map((option, index) => (
                              <option key={index} value={option.id}>
                                {option.text}
                              </option>
                            ))}
                          </Select>
                        </>
                      ) : (
                        <TextInput
                          theme={customThemeTextInput}
                          {...register('secondSelectValue', { required: true })}
                        />
                      )}
                    </div>
                    <div>
                      <Label
                        htmlFor="priority"
                        value={newTicketModalLayout.priority.label.text}
                      />
                      <Select
                        theme={customThemeSelect}
                        {...register('priority', { required: true })}
                        className="mt-1"
                      >
                        {newTicketModalLayout.priority.options.map(
                          (option, index) => (
                            <option value={option.id} key={index}>
                              {option.text}
                            </option>
                          ),
                        )}
                      </Select>
                    </div>
                    <div>
                      <Label
                        htmlFor="nameTag"
                        value={newTicketModalLayout.nameTag.label.text}
                      />
                      <TextInput
                        theme={customThemeTextInput}
                        {...register('nameTag', { required: true })}
                      />
                    </div>
                    <div>
                      <Label
                        htmlFor="description"
                        value={newTicketModalLayout.description.label.text}
                      />
                      <Textarea
                        {...register('description', { required: true })}
                        className="resize-none"
                        id="description"
                        placeholder={
                          newTicketModalLayout.description.label.description ||
                          ''
                        }
                        required
                        rows={4}
                      />
                    </div>
                    <div>
                      <Label
                        htmlFor="attachments"
                        value={newTicketModalLayout.attachments.label.text}
                      />
                      <FileInput
                        id="attachments"
                        multiple
                        helperText={
                          newTicketModalLayout.attachments.label.description
                        }
                        {...register('attachmentsFiles', { required: true })}
                      />
                    </div>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="w-full"
                color="blue"
                type="submit"
                disabled={!problemTypeValue}
              >
                {newTicketModalLayout.submit.text}
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Modal>
    </>
  );
};
