import moment from 'moment';
import { useState } from 'react';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';

import { DateType } from '../Table/FilterComponent';

interface DatepickerComponentProps {
  dateValue: DateType;
  showShortcuts?: boolean;
  dateFormat?: string;
  onDatepickerValueChange?: any; // TODO: set type
}

export const DatepickerComponent: React.FC<DatepickerComponentProps> = ({
  dateValue,
  showShortcuts = false,
  dateFormat = 'MM/DD/YYYY',
  onDatepickerValueChange,
}) => {
  const initialDateValue: DateValueType = {
    startDate: dateValue?.from || null,
    endDate: dateValue?.to || null,
  };

  const [value, setValue] = useState<DateValueType>(initialDateValue);

  const handleValueChange = (newValue: DateValueType) => {
    setValue(newValue);

    if (!onDatepickerValueChange) return;

    if (newValue?.startDate && newValue?.endDate) {
      const formattedValue = {
        from: moment(newValue.startDate).format(dateFormat),
        to: moment(newValue.endDate).format(dateFormat),
      };
      onDatepickerValueChange(formattedValue);
    } else {
      onDatepickerValueChange({});
    }
  };

  return (
    <Datepicker
      value={value}
      onChange={handleValueChange}
      showShortcuts={showShortcuts}
      displayFormat={dateFormat}
      useRange={false}
      placeholder="Please select date range"
      separator="to"
      configs={{
        shortcuts: {
          today: 'Today',
          pastMonth: 'From last month',
          yesterday: 'From a day ago',
          past: period => `Last ${period} days`,
        },
      }}
      inputClassName={
        'p-2 py-3 text-xs w-full rounded-lg bg-[#f9fafb] border-slate-300 cursor-pointer'
      }
    />
  );
};
